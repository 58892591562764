import saveAs from "./saveAs";

import utils from './utils';


/**
 *  template Data 数据
 * var data = [
        { 主页: "111", 名称: "6800", 数量: "6800", 昵称: "广告主网" },
        { 主页: "433", 名称: "6800", 数量: "6800", 昵称: "广告主网" },
        { 名称: "22", 商家: "6800", 数量: "6800", 昵称: "广告主网", },
        { 名称: "43", 商家: "6800", 数量: "6800", 昵称: "广告主网", },
        { 店家: "43", 价格: "6800", 数量: "6800", 昵称: "广告主网", }
      ];

    params  exportExcelBtn  String | HTMLElement
    data: Array 显示数据
 */

export function downloadExport2Excel(exportExcelBtn, data, fileName) {
    let exportBtnDOM = null
    if (exportExcelBtn instanceof HTMLElement) {
        exportBtnDOM = exportExcelBtn
    } else if (typeof exportExcelBtn == 'string') {
        exportBtnDOM = document.querySelector(exportExcelBtn)
    }

    // const doc = document.documentElement || document.body

    if (exportBtnDOM instanceof HTMLElement && Array.isArray(data)) {
        const downExcelFunc = () => {
            data.unshift(utils.readDataHead(data));
            var blob = utils.export(
                {
                    "Sheet1": data,
                    // "Sheet2": [{ "a": "A", "b": "B" }, { "a": 1, "b": "2" }, { "a": 3, "b": 4 }, { "a": 5, "b": 6 }]
                }
            );

            const blobURL = URL.createObjectURL(blob)
            saveAs(blobURL, fileName);

            // exportBtnDOM.removeEventListener('click', downExcelFunc)

            setTimeout(() => {
                URL.revokeObjectURL(blobURL)
            }, 3000)
        }
        exportBtnDOM.addEventListener('click', downExcelFunc)
    }
}

/**
 *  template Data 数据
 * var data = [
        { 主页: "111", 名称: "6800", 数量: "6800", 昵称: "广告主网" },
        { 主页: "433", 名称: "6800", 数量: "6800", 昵称: "广告主网" },
        { 名称: "22", 商家: "6800", 数量: "6800", 昵称: "广告主网", },
        { 名称: "43", 商家: "6800", 数量: "6800", 昵称: "广告主网", },
        { 店家: "43", 价格: "6800", 数量: "6800", 昵称: "广告主网", }
      ];

    data: Array 显示数据
    fileName: string 导出文件名
 */
export function excuteDownloadExport2Excel(data, fileName) {
    data.unshift(utils.readDataHead(data));
    var blob = utils.export(
        {
            "Sheet1": data,
            // "Sheet2": [{ "a": "A", "b": "B" }, { "a": 1, "b": "2" }, { "a": 3, "b": 4 }, { "a": 5, "b": 6 }]
        }
    );

    const blobURL = URL.createObjectURL(blob)
    saveAs(blobURL, fileName);

    // exportBtnDOM.removeEventListener('click', downExcelFunc)

    setTimeout(() => {
        URL.revokeObjectURL(blobURL)
    }, 3000)
}

export default {
    downloadExport2Excel,
    excuteDownloadExport2Excel
}
